<template>
  <div class="dzs-pix">
    <div class="row" style="padding: 15px">

      <div class="col-md-5">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title">
            Depósito via Pix
          </h4>
          <!--          <banner></banner>-->
<!--          <br>-->
<!--          <br>-->
          <p><i class="fas fa-arrow-circle-right text-primary"></i> O QrCode ao lado é único e referente a sua conta.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Para receber PIX é só enviar o QrCode ou a Chave PIX para o pagador.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Compensação imediata do valor na sua conta.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> A taxa de serviço cobrada é de 1% do valor do PIX.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Será cobrada uma taxa mínima de serviço de R$0,50.</p>

        </card>
      </div>

      <div class="col-md-7" >
        <card class="bg-primary" style="height: 100%">
          <h4 slot="header" class="card-title" style="color: #fff; text-align: center">
            Escaneie e pague pelo seu celular
          </h4>
          <div class="col-md-12" style="background-color: #fff;padding: 10% 5% 5% 5%; border-radius: 20px">
            <div class="row">
              <div class="col-md-6">
                <p style="text-align: center;">
                  <qrcode :value="qrCode" :options="{ width: 250, margin:0 }"></qrcode>
                </p>
              </div>
              <div class="col-md-6">
                <div style="text-align: center; padding-bottom: 10px">
                  <p-button @click="copyQrCode" class="btn btn-info" style="margin: 0 auto">
                    <i class="fas fa-copy"/> Copiar Chave Pix
                  </p-button>
                </div>
                <br>
                <div style="text-align: center; padding-bottom: 10px">
                  <p-button @click="printQrCode" class="btn btn-info" style="margin: 0 auto">
                    <i class="fas fa-copy"/> Gerar PDF do QRCode
                  </p-button>
                </div>
                <br>
                <br>
                <div class="row">
                  <div class="col-md-6" style="padding-bottom: 3px">
                    <img src="/static/img/logo-pix.png" alt="..."  >
                  </div>
                  <div class="col-md-6">
                    <img src="/static/img/logo-bancoCentral.png" alt="..."  >
                  </div>
                </div>
                <br>
                <div align="center">
                  <span style="font-size: 12px;">Para usar, escaneie ou copie a chave e envie ao pagador, o mesmo deverá efetuar o pagamento através das opções: <b>Código de pagamento ou Copie e Cole</b>.</span>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>


      <!--<div class="col-md-12" v-if="isNullOrEmpty(pix.brCode)">
             <card>

               <div class="row">
                 <div class="col-md-4">
              <label class="control-label">Valor</label>
              <fg-input placeholder="0,00" v-money="'money'" type="tel"
                        v-model="value" maxlength="14">
                <template slot="addonLeft">R$</template>
              </fg-input>
              <label class="control-label" style="float: right">
                Taxa de Serviço: <b>R$ {{serviceFee}}</b>
              </label>
            </div>
            <div class="col-md-2">
              <p-button type="info" style="margin-top: 25px;"
                        @click="generateNew" v-bind:disabled="registrandoPix">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Gerar Chave Pix
              </p-button>
            </div>
          </div>
        </card>
      </div>-->

      <div class="col-md-12" v-if="!isNullOrEmpty(pix.brCode)">
        <card style="padding: 5%">
          <h4 slot="header" class="card-title" style="color:#598b92; text-align: center">
            CHAVE PIX
          </h4>
          <hr/>
          <div class="row">
            <div class="col-md-12">
              <p style="text-align: center; font-size: 16px">
                <b>VALOR:</b> R$ {{pix.value}}
              </p>
              <p style="text-align: center;">
              <qrcode :value="pix.brCode" :options="{ width: 200, margin:0 }"></qrcode>
              </p>
              <p style="text-align: center; margin: 0; font-size: 12px; margin-left: 15%; margin-right: 15%">
                {{pix.brCode}}
              </p>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-6 col-12" style="text-align: center; padding-bottom: 10px">
              <p-button @click="()=>{pix.brCode = ''}" class="btn btn-info" style="margin: 0 auto">
                <i class="fas fa-asterisk"/> Gerar Nova Chave
              </p-button>
            </div>
            <div class="col-md-6 col-12" style="text-align: center; padding-bottom: 10px">
              <p-button @click="copyDigitableLine" class="btn btn-info" style="margin: 0 auto">
                <i class="fas fa-copy"/> Copiar Chave Pix
              </p-button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="col-md-12" v-if="tableData.length == 0">
      <card>
        <h4 slot="header" class="card-title" align="center">
          Não existem PIX recebidos
        </h4>
      </card>
    </div>

    <div class="col-md-12" v-if="tableData!=null && tableData.length > 0">
      <card>
        <div class="card-body row">
          <div class="col-sm-12" align="center">
            <h4 slot="header" class="card-title">
              Relação de PIX Recebidos
            </h4>
            <hr>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span><b>R$</b> {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Criado">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Venc.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.dueDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Status">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+statusToColor(props.row.status)"></i>
                      <span> {{statusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Data Pgt.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.payDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="90" fixed="right" class-name="td-actions">
                <template slot-scope="props" v-if="props.row.status == 'CREATED'">
                  <p-button type="info" size="sm" icon title="Exibir chave pix"
                            @click="showBarcode(props.row.brCode, toMoney(props.row.value))">
                    <i class="fas fa-qrcode"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>

    <input type="hidden" id="digitableLine-copy" :value="pix.brCode">
    <input type="hidden" id="qrcode-copy" :value="qrCode">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {postWs, failWs, getWs} from "src/ws.service"
  import {toMoney, moneyToFloat, isNullOrEmpty} from "src/util/core.utils"
  import swal from 'sweetalert2'
  import Banner from "../../../UIComponents/Promotion/Banner";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Banner
    },
    data() {
      return {
        serviceFee: '1,00 %',
        value: '0,00',
        registrandoPix: false,
        qrCode: '',
        pix: {
          brCode: '',
          value: '',
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      generateNew() {
        this.registrandoPix = true;
        postWs("/pix/request-pix",
          true, {value: moneyToFloat(this.value)}, null, this.successGenerateNew, this.failGenerateNew)
      },
      successGenerateNew(response) {
        this.registrandoPix = false;
        this.pagination.currentPage = 1;
        this.showBarcode(response.data, this.value);
        this.value = '0,00';
        this.loadData();
      },
      failGenerateNew(error) {
        this.registrandoPix = false;
        let text = 'Falha ao gerar Chave Pix! Tente mais tarde.'
        if (error.response.status == '404') {
          text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação no menu \'VERIFICAR CONTA\'.'
        }
        swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      showBarcode(pix, value) {
        this.pix.brCode = pix;
        this.pix.value = value;
      },
      loadData() {
        postWs("/pix/last-requested-pix",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, this.errorLoadData)
      },
      successLoadData(response) {
        this.qrCode = response.data.qrCode
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      errorLoadData(error) {
        if(error.response.status == 400 && error.response.data.response != null){
          swal({
            title: 'Aviso!',
            text: error.response.data.message,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          swal({
            title: 'Aviso!',
            text: 'Erro',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      statusToLabel(status) {
        if ('PAID_OUT' == status) {
          return 'Pago'
        } else if ('CREATED' == status) {
          return 'Pendente'
        } else if ('REVERSAL' == status) {
          return 'Devolvido'
        } else {
          return 'Cancelada'
        }
      },
      statusToColor(status) {
        if ('PAID_OUT' == status) {
          return '6bd098'
        } else if ('CREATED' == status) {
          return 'fbc658'
        } else {
          return 'ef8157'
        }
      },
      copyDigitableLine() {
        let digitableLineCopy = document.querySelector('#digitableLine-copy')
        digitableLineCopy.setAttribute('type', 'text')
        digitableLineCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              text: 'Chave Pix copiada com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              text: 'Erro ao copiar Chave Pix!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar Chave Pix!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        digitableLineCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      copyQrCode() {
        let digitableLineCopy = document.querySelector('#qrcode-copy')
        digitableLineCopy.setAttribute('type', 'text')
        digitableLineCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              text: 'Chave Pix copiada com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              text: 'Erro ao copiar Chave Pix!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar Chave Pix!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        digitableLineCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      printQrCode() {
        getWs('/pix/get-qrcode-pdf', true, null,
          this.successPrintQrcode,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'QrCode não encontrado!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o PDF, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successPrintQrcode(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>3RBank - QrCode</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.pdfBase64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do PDF, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  .dzs-pix {
    .vue-barcode-element {
      height: 86px !important;
      display: table;
      margin: 0 auto !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    @media (max-width: 800px) {
      .vue-barcode-element {
        height: 50px !important;
        width: 330px !important;
      }
    }
  }
</style>
