<template>
  <div class="dzs-acc-edit">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Editar Cadastro Adquirente</h4>
          </div>
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-md-3">
                  <fg-input type="text"
                            label="Usuário"
                            disabled
                            v-model="username">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="email"
                            label="E-mail"
                            disabled
                            v-model="account.email">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Nome Completo"
                            maxlength="70"
                            name="Nome Completo"
                            v-validate="{required: true}"
                            :error="getError('Nome Completo')"
                            v-model="account.name"
                            disabled>
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input label="Tipo de Pessoa">
                    <el-select size="large"
                               placeholder="Selecionar"
                               v-model="account.personType"
                               disabled>
                      <el-option class="select-success"
                                 value="NATURAL_PERSON"
                                 label="Pessoa Física"/>
                      <el-option class="select-info"
                                 value="LEGAL_PERSON"
                                 label="Pessoa Jurídica"/>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-7">
                  <fg-input :label="account.personType=='NATURAL_PERSON'?'CPF':'CNPJ'"
                            type="tel"
                            v-mask="account.personType=='NATURAL_PERSON'?['###.###.###-##']:['##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-validate="{required: true, min: 14}"
                            :error="getError('CPF/CNPJ')"
                            v-model="account.socialNumber"
                            disabled>
                  </fg-input>
                </div>
              </div>
              <div v-if="account.personType=='LEGAL_PERSON'">
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="text"
                              label="Razão Social"
                              maxlength="100"
                              name="Razão Social"
                              v-validate="{required: true}"
                              :error="getError('Razão Social')"
                              v-model="account.legalPerson.companyName"
                              disabled>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <fg-input type="text"
                              maxlength="255"
                              label="Atividade Principal"
                              name="Atividade Principal"
                              v-validate="{required: true}"
                              :error="getError('Atividade Principal')"
                              v-model="account.legalPerson.mainActivity"
                              disabled>
                    </fg-input>
                  </div>
                  <div class="col-md-5">
                    <div class="row" style="padding-left: 15px">
                      <fg-input type="tel"
                                v-mask="['##/##/####']"
                                label="Data de Constituição"
                                name="Data de Constituição"
                                v-validate="{required: true, min:10}"
                                v-model="account.legalPerson.openingDate"
                                :error="getError('Data de Constituição')"
                                disabled></fg-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 30px" v-show="account.accountBusinessData.registered">
                <div class="col-md-6">
                  <fg-input type="text"
                            maxlength="100"
                            label="Site"
                            v-model="account.accountBusinessData.site"
                            disabled
                            key="site">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            maxlength="100"
                            label="Mídia Social"
                            v-model="account.accountBusinessData.socialMedia"
                            disabled
                            key="socialMedia">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            maxlength="100"
                            label="Área de Atuação"
                            v-model="account.accountBusinessData.businessSegment.description"
                            disabled
                            key="socialMedia">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            maxlength="100"
                            label="Média de Faturamento"
                            v-model="account.accountBusinessData.averageBilling.description"
                            disabled
                            key="socialMedia">
                  </fg-input>
                </div>
              </div>

              <div class="row" style="padding-top: 30px">
                <div class="col-md-12">
                  <h6 class="title">Dados da Adquirente</h6>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Status"
                            disabled
                            v-model="account.accountGatewayAcquirer.statusView">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="E-mail Cadastro Adquirente"
                            disabled
                            v-model="account.accountGatewayAcquirer.emailAcquirer">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="ID cadastro"
                            disabled
                            v-model="account.accountGatewayAcquirer.idAcquirer">
                  </fg-input>
                </div>
              </div>

              <div class="row" style="padding-top: 30px">
                <div class="col-md-12">
                  <h6 class="title">Dados Bancários</h6>
                </div>
                <div class="col-md-7">
                  <fg-input type="text"
                            label="Banco"
                            disabled
                            v-model="account.bankDetails.bankCode+' - '+account.bankDetails.bankName">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Tipo da Conta"
                            disabled
                            v-model="account.bankDetails.accountType=='CC'?'Conta Corrente':'Conta Poupança'">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <fg-input type="text"
                            label="Agência"
                            disabled
                            v-model="account.bankDetails.agencyNumber">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="DV Agência"
                            disabled
                            v-model="account.bankDetails.agencyDv">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="text"
                            label="Conta"
                            disabled
                            v-model="account.bankDetails.accountNumber">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="DV Conta"
                            disabled
                            v-model="account.bankDetails.accountNumberDv">
                  </fg-input>
                </div>
              </div>
              <div class="row" v-if="account.personType=='LEGAL_PERSON'">
                <div class="col-md-7">
                  <fg-input type="text"
                            label="Titular da Conta"
                            disabled
                            v-model="account.bankDetails.ownerName">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input :label="'CPF do Titular'"
                            type="tel"
                            v-mask="'###.###.###-##'"
                            name="CPF"
                            v-model="account.bankDetails.ownerDocument"
                            disabled>
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center" v-if="account.accountGatewayAcquirer.status == 'WAITING_SENT_BANK_DETAILS'">
                  <p-button type="success" style="margin-top: 25px;"
                            @click="updateStatus(username, 'WAITING_APPROVAL')">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Documentos Enviados Para Adquirente
                  </p-button>
                </div>
                <div class="col-md-12" style="text-align: center" v-if="account.accountGatewayAcquirer.status == 'WAITING_APPROVAL'">
                  <p-button type="success" style="margin-top: 25px;"
                            @click="updateStatus(username, 'APPROVED')">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Conta Aprovada na Adquirente
                  </p-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-for="image in images" v-if="!$route.path.includes('clientDetail')">
        <div class="card">
          <div class="card-body" style="margin: 0 auto">
            <div class="row">
              <div class="col-md-12 doc-image">
                <a href="#" @click="openDoc(image)">
                  <embed width="900" height="900" :src='loadDocSrc(image)'
                         v-if="'pdf'==image.imageFileType.replace(' ', '').toLowerCase()"/>
                  <img :src="loadDocSrc(image)" v-else/>
                </a>

                <fg-input type="text" disabled
                          v-model="image.id"/>

                <fg-input type="text" disabled
                          v-model="image.documentName"/>

                <div style="display:table; margin: 0 auto !important">
                  <fg-input>
                    <el-select size="large" disabled
                               placeholder="Selecionar"
                               v-model="image.documentPhotoType">
                      <el-option value="PHOTO_DOCUMENT"
                                 label="Foto da Frente do Documento"/>
                      <el-option value="BACK_PHOTO_DOCUMENT"
                                 label="Foto do Verso do Documento"/>
                      <el-option value="PROOF_OF_RESIDENCE"
                                 label="Comprovante de Endereço"/>
                      <el-option value="SELFIE"
                                 label="Selfie"/>
                      <el-option value="CNPJ_CARD"
                                 label="Cartão CNPJ"/>
                      <el-option value="SOCIAL_CONTRACT"
                                 label="Contrato Social"/>
                      <el-option value="PROOF_OF_COMPANY_ADDRESS"
                                 label="Comprovante Endereço Juridico"/>
                    </el-select>
                  </fg-input>
                </div>
              </div>
            </div>
            <div class="row">
              <button class="btn btn-info btn-fill btn-wd" style="margin: 20px auto"
                      @click="openDoc(image)">
                <i class="fas fa-search-plus"></i>
                VISUALIZAR DOCUMENTO EM NOVA JANELA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {postWs, getWs, failWs} from 'src/ws.service'
  import {isNullOrEmpty} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import PButton from "../../../../UIComponents/Button";

  export default {
    components: {PButton},
    emits: ['accAcquirerStatusChanged'],
    data() {
      return {
        username: '',
        formDisabled: false,
        account: {
          name: '',
          email: '',
          socialNumber: '',
          personType: 'NATURAL_PERSON',
          phoneNumber1: '',
          phoneNumber2: '',
          accountDocumentStatus: '',
          legalPerson: {
            companyName: '',
            openingDate: '',
            mainActivity: ''
          },
          accountBusinessData: {
            registered: false,
            site: '',
            socialMedia: '',
            averageBilling: {
              id: '',
              description: '',
            },
            businessSegment: {
              id: '',
              description: '',
            },
          },
          isBankDetails: false,
          isAccountGatewayAcquirer: false,
          bankDetails: {
            username: '',
            bankCode: '',
            bankName: '',
            accountType: '',
            agencyNumber: '',
            agencyDv: '',
            accountNumber: '',
            accountNumberDv: '',
            ownerName: '',
            ownerDocument: '',
          },
          accountGatewayAcquirer: {
            status: '',
            statusView: '',
            emailAcquirer: '',
            idAcquirer: '',
          }
        },
        images: []
      }
    },
    created() {
      this.username = this.$route.params.username
      this.loadData()
    },
    methods: {
      updateStatus(username, status) {
        postWs("/bo/acc/update-status-account-gateway", true,
          null, {username: username, status: status},
          (response) => {
            swal({
              title: 'Sucesso!',
              text: 'Status atualizado com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
            this.$root.$emit('accAcquirerStatusChanged')
          },
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao atualizar status. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      loadData() {
        postWs("/acc/get-account", true,
          {username: this.username}, null,
          this.loadAccount, (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao buscar dados. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })

        postWs("/document/status", true,
          {username: this.username}, null,
          this.loadDoc, (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao buscar documentos. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      loadAccount(response) {
        this.account = response.data
        this.account.accountGatewayAcquirer.statusView = this.statusToLabel(this.account.accountGatewayAcquirer.status)
        this.formDisabled = true
      },
      loadDoc(response) {
        this.images = []
        if (response.data.length > 0) {
          for (let i in response.data) {
            this.loadDocData(response.data[i])
          }
        }
      },
      loadDocData(image) {
        getWs("/bo/document/image", true,
          {id: image.id},
          (response) => {
            this.images.push(response.data)
          }, failWs)
      },
      loadDocSrc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        if (fileType == 'pdf') {
          return 'data:application/pdf;base64,' + image.imageBase64
        } else if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + image.imageBase64
        }
        return 'data:image/' + fileType + ';base64,' + image.imageBase64
      },
      openDoc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        let imageDimension = " width='100%' "
        if (fileType == 'pdf') {
          imageDimension += " height='100%' "
        }
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>3RBank - Validação de Documento</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed" + imageDimension + "src='" + this.loadDocSrc(image) + "'></embed></body></html>"
          )
          ;
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do documento, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      statusToLabel(documentStatus) {
        if (documentStatus == 'WAITING_SENT') {
          return 'Ag. Envio de Dados Adquirente'
        } else if (documentStatus == 'WAITING_SENT_BANK_DETAILS') {
          return 'Ag. Envio de Dados Bancários'
        } else if (documentStatus == 'WAITING_APPROVAL') {
          return 'Ag. Aprovação'
        } else if (documentStatus == 'DISAPPROVED') {
          return 'Reprovado'
        } else if (documentStatus == 'APPROVED') {
          return 'Aprovado'
        } else if (documentStatus == 'INACTIVE') {
          return 'Inativo'
        } else if (documentStatus == 'INACTIVE_WITH_CHECKOUT') {
          return 'Inativo com Acesso ao Checkout'
        } else if (documentStatus == 'FAIL') {
          return 'Erro ao Processar Dados'
        }
        return '??'
      },
    }
  }

</script>
<style lang="scss">
  .dzs-acc-edit {
    .doc-image input {
      min-width: 400px;
      text-align: center;
      color: #000000 !important;
    }
  }
</style>
