<template>
  <div class="card" v-if="files.length>0">
    <div class="card-header">
      <h4 class="title" style="margin-bottom: 0px">Enviar Documentos</h4>
      <p>(Tamanho máximo: 5MB por imagem)
      <p/>
    </div>
    <div class="card-body">
      <form>
        <div class="row" v-for="item in files">
          <div class="col-md-12">
            <div class="row" style="margin-left: 0px">
              <label style="font-size: 13px; color: #11141f">{{item.title}}</label>
            </div>
            <div class="row" style="padding: 0 14px">
              <p-button :class="item.styleClass" style="margin-top: 0px; width: 100%"
                        @click.prevent="function (){if(isProfileOk()) {($refs[item.ref])[0].click()}}"
                        :disabled="item.sending">
                <i class="fa fa-upload"/> {{item.filename}}
              </p-button>
              <input type="file" :ref="item.ref" style="display: none" accept=".png, .jpg, .jpeg, .pdf"
                     @change="function (event){validateFile(event,item)}"/>
            </div>
          </div>
        </div>

        <br/>
        <div class="text-center">
          <p-button class="btn btn-info btn-fill btn-wd"
                    @click="uploadFiles" :disabled="button.waiting!=0">
            {{this.button.text}}
          </p-button>
          <p style="font-size: 13px">*Formatos aceitos: PNG, JPEG, JPG e PDF.
          <p/>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import {callWs} from 'src/ws.service'
  import {isNullOrEmpty} from "src/util/core.utils"
  import swal from 'sweetalert2'

  export default {
    components: {},
    data() {
      return {
        fileMaxSize: 5,
        button: {
          text: "Enviar Documentos",
          waiting: 0
        },
        userProfileKey: 0,
        files: [],
        typesNaturalPerson: [
          {
            type: 'PHOTO_DOCUMENT',
            title: 'Documento RG ou CNH ou Passaporte - Frente',
            ref: 'document',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'BACK_PHOTO_DOCUMENT',
            title: 'Documento RG ou CNH ou Passaporte - Verso',
            ref: 'back_document',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'PROOF_OF_RESIDENCE',
            title: 'Comprovante de endereço (Em nome do proprietário da conta ou familiares próximos (pai, mãe, cônjugue), e emitido há no máximo 3 meses)',
            ref: 'address',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'SELFIE',
            title: 'Selfie segurando o documento',
            ref: 'selfie',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          }
        ],
        typesLegalPerson: [
          {
            type: 'CNPJ_CARD',
            title: '(Empresa) Cartão do CNPJ',
            ref: 'cnpj_card',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'SOCIAL_CONTRACT',
            title: '(Empresa) Contrato social',
            ref: 'social_contract',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'PROOF_OF_COMPANY_ADDRESS',
            title: '(Empresa) Comprovante de endereço (A titularidade deverá ser a mesma do CNPJ cadastrado ou sócios, e emitido há no máximo 3 meses)',
            ref: 'company_address',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'PHOTO_DOCUMENT',
            title: '(Representante Legal) Documento RG ou CNH ou Passaporte - Frente',
            ref: 'document',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'BACK_PHOTO_DOCUMENT',
            title: '(Representante Legal) Documento RG ou CNH ou Passaporte - Verso',
            ref: 'back_document',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'PROOF_OF_RESIDENCE',
            title: '(Representante Legal) Comprovante de endereço (Em nome do proprietário da empresa ou familiares próximos (pai, mãe, cônjugue), e emitido há no máximo 3 meses)',
            ref: 'address',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          },
          {
            type: 'SELFIE',
            title: '(Representante Legal) Selfie segurando o documento',
            ref: 'selfie',
            filename: 'Selecionar imagem',
            styleClass: 'btn btn-danger',
            sending: false,
            show: true,
            file: null
          }
        ],
        password: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }
      }
    },
    mounted() {
      callWs("/document/status",
        "POST", null, true, null, null,
        this.loadUploadCard, function (error) {
        }
      )
    },
    watch: {
      files: function(val) {
        if(val==0){
          this.$router.push('/user/profile')
        }
      }
    },
    methods: {
      loadUploadCard(response) {
        let personType = localStorage.getItem('personType')
        let types = this.typesNaturalPerson
        if (personType == 'LEGAL_PERSON') {
          types = this.typesLegalPerson
        }
        this.files = []
        let errorCauseCount = 0
        let errorCauseMsg = ""
        for (let i in types) {
          let type = types[i]
          let add = true
          let descriptionError = ''
          for (let j in response.data) {
            let server = response.data[j]
            if (type.type == server.documentPhotoType) {
              if (server.documentPhotoStatus == 'OK'
                || server.documentPhotoStatus == 'WAITING_APPROVAL') {
                add = false
                break
              }else if(server.documentPhotoStatus == 'REJECTED'){
                descriptionError = server.descriptionError
              }
            }
          }
          if (add) {
            if (!isNullOrEmpty(descriptionError)) {
              ++errorCauseCount
              errorCauseMsg += errorCauseCount.toString() + " - " + descriptionError + " <br/>"
            }
            this.files.push(JSON.parse(JSON.stringify(type)))
          }
        }
        if (errorCauseCount > 0) {
          let errorExplanation = '- Para validarmos sua conta é necessário que os documentos sejam válidos, legíveis e coincidam com os informados no formulário!'
          errorExplanation += ' <br/>'
          errorExplanation += '- Abaixo possui os motivos da recusa dos documentos, favor analisar e reenviá-los.'
          errorExplanation += ' <br/><br/>'
          swal({
            title: 'Não foi possivel validar a conta!',
            html: errorExplanation + errorCauseMsg,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      validateFile(event, upload) {
        if (event.target.files.length <= 0) {
          return
        } else if (event.target.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
          swal({
            title: 'Aviso!',
            text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        upload.file = event.target.files[0]
        upload.filename = upload.file.name
        upload.styleClass = "btn btn-primary"
      },
      uploadFiles() {
        this.userProfileKey++
        if (!this.isProfileOk()) {
          return
        }
        this.button.text = "Favor aguardar envio"
        for (let file in this.files) {
          if (this.files[file].file != null) {
            ++this.button.waiting
            this.callUploadWs(this.files[file])
          }
        }
      },
      isProfileOk() {
        let name = localStorage.getItem("name")
        if (name != null && name != 'null' && name != '') {
          return true
        }
        swal({
          title: 'Aviso!',
          text: 'Favor preencher os campos do formulário de perfil antes de enviar a documentação.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return false
      },
      callUploadWs(upload) {
        upload.filename = "Enviando..."
        upload.styleClass = "btn btn-warning"
        upload.sending = true
        let formData = new FormData()
        formData.append("file", upload.file)
        callWs("/document/upload",
          "POST", {'Content-Type': 'multipart/form-data'},
          true, {fileType: upload.type}, formData,
          response => {
            this.uploadSuccess(response, this.files, upload)
          },
          (error) => {
            upload.filename = "Falhou! Tentar novamente"
            upload.styleClass = "btn btn-danger"
            upload.sending = false
            this.button.text = "Enviar Documentos"
            --this.button.waiting
            let text = 'Falha ao salvar um ou mais documentos, por favor tente novamente!'
            if (!isNullOrEmpty(error.response.data.response)) {
              text = error.response.data.message
            }
            swal({
              title: 'Falha!',
              text: text,
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          }
        )
      },
      uploadSuccess(response, files, upload) {
        upload.sending = true
        upload.styleClass = "btn btn-primary"
        upload.filename = "ENVIADO COM SUCESSO!"

        this.button.text = "Enviar Documentos"
        --this.button.waiting
        setTimeout(function () {
          for (let file in files) {
            if (files[file].type == upload.type) {
              files.splice(file, 1)
              break
            }
          }
        }, 2000)
      }
    }
  }

</script>
<style>

</style>
